import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";

const Container = tw.div`relative lg:-mt-0`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between max-w-screen-xl mx-auto py-20 sm:py-2 lg:py-0`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none sm:max-w-full  md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0  md:h-auto relative p-0 m-0 lg:w-[60%] lg:-ml-32 hidden lg:block`;
const ImageColumnMobile = tw(Column)`md:w-[100%]  min-[400px]:max-[420px]:-mb-[4rem]  min-[400px]:max-[500px]:-mb-[2.5rem] 
                          min-[400px]:h-[430px] max-[400px]:h-[400px] sm:-mb-[100px]  md:-mb-[3rem]   flex-shrink-0 h-[750px] 
                          mx-auto p-0 m-0  mt-[1.4rem] pb-[3rem] lg:hidden max-[350px]:h-[350px]`;

const TextColumn = styled(Column)(props => [
  tw`md:w-full w-full md:mt-0 md:p-[0rem] `,
  props.textOnLeft ? tw`md:mr-0 lg:mr-[0px] lg:mr-[30px] lg:mr-[30px] xl:ml-[70px] md:order-first` : tw`md:ml-0  md:order-last `
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center mx-auto w-[100%] sm:h-[100%] lg:w-[90%] lg:h-[125%] p-0 m-0 lg:ml-[5rem] 
  lg:-mt-8 lg:pl-12 
  md:w-[100%] md:h-[500px] min-[200px]:w-[110%] min-[200px]:-ml-[20px] min-[400px]:h-[450px] min-[450px]:max-[767px]:-ml-[19px] lg:h-[510px] lg:mt-[50px]`
]);



const TextContent = tw.div`lg:py-8  text-center md:text-center`;

const Subheading = tw(SubheadingBase)`text-center lg:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-3xl sm:text-3xl  lg:text-4xl text-center lg:text-left leading-tight lg:w-[100%] md:w-[100%] sm:w-[100%] font-sans md:font-extrabold font-bold 
min-[400px]:max-[437px]:-mb-[15px]`;
const Description = tw.p`mt-4 text-left md:text-left text-[1.2rem] md:text-base sm:mt-[5rem] min-[501px]:mt-[10rem] lg:mt-[2rem] lg:text-lg font-medium leading-loose text-secondary-100`;

const Statistics = tw.div`flex flex-col sm:block text-left md:text-left mt-12 max-[450px]:w-[100%] max-[450px]:-ml-[15px]`;
const Statistic = tw.div`text-left sm:block  last:mr-0 mt-5 `;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-primary-500`;

export default ({
  subheading = "Campaña de recuperación",
  heading = (
    <>
      Reactivación de clientes perdidos
    </>
  ),
  description = (
    <>
    Recuérdale a los <strong>clientes inactivos</strong> de tu e-commerce, que los extrañas y que tienes algo bueno para ofrecerles.
    <br></br><br></br>
    El objetivo es <strong>reconectar a los clientes que te compraron hace un tiempo</strong> pero no han vuelto a realizar un nuevo pedido. 
    </>
  ),

  imageSrc = "https://desarrollospec.com/imagenes/web-cliente-recurrente/opt/reactiva-clientes-inactivos-ecommerce.gif",

  imageCss = "",
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "👋 Recupera clientes antiguos",
      value: ""
    },
    {
      key: "💡 Promueve el crosselling",
      value: ""
    },
    {
      key: "💎 Diferénciate de la competencia",
      value: ""
    },
    {
      key: "📈 Genera datos sobre el comportamiento de tus clientes y toma mejores decisiones",
      value: ""
    }
  ];

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>

        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <ImageColumnMobile>
              <Image imageSrc={imageSrc}  /> 
            </ImageColumnMobile>
            <Description>{description}</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>

          </TextContent>
        </TextColumn>
        <ImageColumn >
          {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
        </ImageColumn>
      </TwoColumn>
    </Container>
  );
};
