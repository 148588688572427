import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { PrimaryButton } from "../misc/Buttons.js";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-4 mb-[4rem] lg:py-24 lg:-mb-[5rem] min-[600px]:mb-[25rem]`;
const TestimonialsContainer = tw.div`mt-16 lg:mt-0 w-[100%] h-[26rem]`;
const Testimonials = styled.div``;
const Testimonial = tw.div` lg:max-w-none lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row `;
const TestimonialImageSlider = tw(Slider)`w-full lg:w-7/12 flex-shrink-0 hidden lg:block `;
const TestimonialImageSliderMobile = tw(Slider)` p-[0rem] w-[115%]  flex-shrink-0 mt-[1rem]   lg:hidden `;
const ImageAndControlContainer = tw.div`relative outline-none `;
const ImageAndControlContainerMobile = tw.div`relative outline-none mt-[23px]`;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center  max-[1198px]:h-[28rem] min-[1199px]:h-[32rem]  lg:block hidden`
]);

const ImageMobile = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover mx-auto max-[310px]:-ml-[20px] max-[310px]:w-[113%]  h-[290px] w-[290px] 
  
  min-[350px]:h-[333px] min-[350px]:w-[333px] 
  min-[320px]:h-[303px] min-[320px]:w-[303px]
  min-[420px]:h-[410px] min-[420px]:w-[410px] 
  min-[600px]:h-[585px] min-[600px]:w-[585px]  
`
]);

const ControlContainer = tw.div`absolute bottom-[6rem]    py-2 rounded-3xl hidden lg:block 
                            max-[1198px]:bottom-[10rem] 
                            min-[1199px]:bottom-[12rem]
                            `;
const ControlContainerMobile = tw.div` bottom-[-2rem] 
                                      max-[1024px]:mt-[0.5rem] 
                                      min-[420px]:-mt-[0.9rem] 
                                      
                                      
                                      mx-auto px-6 -mb-[2rem] rounded-3xl  block lg:hidden  overflow-visible `;

const ControlButton = styled(PrimaryButton)`
  ${tw`mx-4 rounded-full lg:p-3 border-0`}
  svg {
    ${tw`w-6 h-6 `}
  }
`;

const ControlButtonMobile = styled(PrimaryButton)`
  ${tw`mx-4 rounded-full p-2 border-0`}
  svg {
    ${tw`w-8 h-8`}
  }
`;

const TextContainer = styled.div(props => [
  tw`flex flex-col w-full lg:w-5/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last lg:-mt-[3rem]`
]);

const Subheading = tw(SubheadingBase)`mb-4`;
const HeadingTitle = tw(SectionHeading)`lg:text-left leading-tight md:text-4xl text-3xl font-bold md:font-extrabold font-sans `;
const Description = tw.p`lg:max-w-md  text-left mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-loose -mb-[4rem] lg:-mb-0  lg:text-lg font-medium mt-4 text-[1.2rem]`;


export default ({
  subheading = "",
  heading = "Analíticas & KPIs",
  description = "",
  testimonials = null,
  textOnLeft = false
}) => {
  /*
   * You can modify the testimonials shown by modifying the array below or passing in the testimonials prop above
   * You can add or remove objects from the array as you need.
   */
  const defaultTestimonials = [
    {
      imageSrc:
        "https://desarrollospec.com/imagenes/web-cliente-recurrente/opt/dashboard-recompras-clientes.jpg",
    },
    {
      imageSrc:
        "https://desarrollospec.com/imagenes/web-cliente-recurrente/opt/dashboard-compras-programadas-final.jpg",
    }
  ];

  const defaultTestimonialsMobile = [
    {
      imageSrc:
        "https://desarrollospec.com/imagenes/web-cliente-recurrente/opt/dashboard-recompras-clientes-mobo.jpg",
    },
    {
      imageSrc:
        "https://desarrollospec.com/imagenes/web-cliente-recurrente/opt/dashboard-compras-programadas-final-mobo.jpg",
    }
  ];

  if (!testimonials || testimonials.length === 0) testimonials = defaultTestimonials;

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [imageSliderRefMobile, setImageSliderRefMobile] = useState(null);

  const [textSliderRef, setTextSliderRef] = useState(null);

  return (
    <Container>
      <Content>
        <HeadingInfo tw="text-center lg:hidden" subheading={subheading} heading={heading} description={description} />
        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <TestimonialImageSlider arrows={false} ref={setImageSliderRef} asNavFor={textSliderRef} fade={true}>
                {testimonials.map((testimonial, index) => (
                  <ImageAndControlContainer key={index}>
                    <Image imageSrc={testimonial.imageSrc} />
                    <ControlContainer>
                      <ControlButton onClick={imageSliderRef?.slickPrev}>
                        <ChevronLeftIcon />
                      </ControlButton>
                      <ControlButton onClick={imageSliderRef?.slickNext}>
                        <ChevronRightIcon />
                      </ControlButton>
                    </ControlContainer>
                  </ImageAndControlContainer>
                ))}
              </TestimonialImageSlider>

              <TestimonialImageSliderMobile arrows={false} ref={setImageSliderRefMobile} asNavFor={textSliderRef} fade={true}>
                {defaultTestimonialsMobile.map((testimonial, index) => (
                  <ImageAndControlContainerMobile key={index}>
                    <ImageMobile imageSrc={testimonial.imageSrc} />
                    <ControlContainerMobile>
                      <ControlButtonMobile onClick={imageSliderRefMobile?.slickPrev}>
                        <ChevronLeftIcon />
                      </ControlButtonMobile>
                      <ControlButtonMobile onClick={imageSliderRefMobile?.slickNext}>
                        <ChevronRightIcon />
                      </ControlButtonMobile>
                    </ControlContainerMobile>
                  </ImageAndControlContainerMobile>
                ))}
              </TestimonialImageSliderMobile>

              <TextContainer textOnLeft={textOnLeft}>
                <HeadingInfo tw="hidden lg:block" subheading={subheading} heading={heading} description={description} />
              </TextContainer>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
      </Content>
    </Container>
  );
};

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    {subheading ? <Subheading>{subheading}</Subheading> : null}
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>
    Recopilamos datos y KPIs relevantes en cada interacción, proporcionando información continua y automatizada sobre la recurrencia de tus clientes, permitiéndote tomar decisiones basadas en datos.
    <br></br>
    <br></br>
    Hacemos análisis y visualización de data a través de Power BI. Nuestros clientes cuentan con un panel online donde pueden visualizar los reportes generados por sus campañas en cualquier momento.
   </Description>
  </div>
);
