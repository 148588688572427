import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo-cliente-recurrente-pec.svg";
import { Link as LinkRouter } from 'react-router-dom'
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";


const PrimaryButton = tw(PrimaryButtonBase)` inline-block  tracking-wide text-center font-semibold py-1 lg:text-[16px] block`;

const Container = tw(ContainerBase)`bg-gray-800 text-gray-100 -mx-12 -mb-20 `
const Content = tw.div`max-w-screen-xl mx-auto py-20 `;

const Row = tw.div`flex items-center justify-center flex-col px-8`
const MarginTop = tw.div`min-[462px]:mt-[15px] min-[532px]:mt-0`
const MarginTopPoliticas = tw.div`min-[462px]:mt-[15px] min-[717px]:mt-0`



const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col min-[462px]:flex-row`


const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Cliente Recurrente</LogoText>
          </LogoContainer>
          <LinksContainer>
            <LinkRouter to="/">Inicio</LinkRouter> &nbsp; &nbsp; &nbsp; 
            <a href="https://wa.link/e9hezy" target="_blank"><PrimaryButton as="a"  >
            💬 Hablemos 
              </PrimaryButton></a>&nbsp; &nbsp; &nbsp; 
            
            <LinkRouter to="/terminos-y-condiciones"><MarginTop>Términos y condiciones</MarginTop></LinkRouter> &nbsp; &nbsp; &nbsp; 
            <LinkRouter to="/politica-de-privacidad"><MarginTopPoliticas>Política de privacidad</MarginTopPoliticas></LinkRouter> &nbsp; &nbsp; &nbsp; 

          </LinksContainer>
          <CopyrightText>
            hola@clienterecurrente.cl | 👨‍💻 Hecho en Chile acompañados por nuestros amores: 🐶 · ☕ · ☘️<br></br><br></br> 
            2024, Cliente Recurrente es una marca de Desarrollos PEC SpA. RUT: 77.731.737- 7<br></br><br></br>
            Somos <b>Meta Business Partners en la especialidad de WhatsApp API</b><br></br><br></br><br></br><br></br>
            <i>Meta™, WhatsApp™ y Microsoft Power BI™ son marcas que no tienen ninguna asociación con Cliente Recurrente</i>
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
