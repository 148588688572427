import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { Container as ContainerBase} from "components/misc/Layouts";
import "slick-carousel/slick/slick.css";

const Container = tw(ContainerBase)`relative bg-gray-300 p-[2rem] lg:p-[2rem] lg:mt-[2rem] w-[80%] lg:w-[100%] lg:mb-[5rem] box-content lg:-ml-[2rem]`;
const Content = tw.div`max-w-screen-xl mx-auto `;

const TestimonialSliderContainer = tw.div`mt-2`;

const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none lg:w-full`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-0 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none pt-[2rem] lg:pt-0 lg:ml-[2rem]`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-0 lg:pt-12 mt-2 md:mt-0 lg:-ml-[4rem]`;
const Quote = tw.blockquote`text-left md:text-left font-medium text-xl lg:text-2xl xl:text-2xl`;
const CustomerInfo = tw.div`px-5 lg:px-0 lg:pt-8 text-center md:text-left mt-4 md:mt-0 lg:-ml-[4rem]`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-sm`;

const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;



export default () => {
  const testimonials = 
    {
      imageSrc:
        "https://desarrollospec.com/imagenes/zendesk-logo-recurrencia-de-compra1.png",
      quote:
        "Es más caro obtener nuevos clientes que retener a quienes ya te compran.",
      customerName: "Equipo Zendesk",
      customerTitle: "Blog Zendesk, 2022.",
      id:1
    }
  ;
  return (
    <Container>
      <Content>
        <TestimonialSliderContainer>
              <Testimonial key={testimonials.id}>
                <ImageContainer>
                  <img src={testimonials.imageSrc} alt={testimonials.customerName} />
                </ImageContainer>
                <TextContainer>
                  <QuoteContainer>
                    <QuotesLeft />
                    <Quote>Es <strong>más caro obtener nuevos clientes</strong> que retener a quienes ya te compran. <br></br><br></br>
                    Adquirir un nuevo cliente, <strong>puede costar hasta 25 veces más</strong> (según la industria).</Quote>
                    <QuotesRight />
                  </QuoteContainer>
                  <CustomerInfo>
                    <CustomerName>{testimonials.customerName}</CustomerName>
                    <CustomerTitle> <a href="https://www.zendesk.com.mx/blog/que-es-significa-cac/" target="_blank">{testimonials.customerTitle} <i>Leer artículo</i></a></CustomerTitle>
                  </CustomerInfo>
                </TextContainer>
              </Testimonial>
        </TestimonialSliderContainer>
      </Content>
    </Container>
  );
};
