import './App.css';
import "style.css"
import "tailwindcss/lib/css/preflight.css"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PoliticaDePrivacidad from 'pages/PoliticaDePrivacidad';
import TerminosyCondiciones from 'pages/TerminosyCondiciones';
import RegistroFacebookPec from 'pages/RegistroFacebookPec';           
import PecHome from 'pages/PecHome';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PecHome />} />
        <Route path="/politica-de-privacidad" element={<PoliticaDePrivacidad />} />
        <Route path="/terminos-y-condiciones" element={<TerminosyCondiciones />} />
        <Route path="/registro-fb" element={<RegistroFacebookPec />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
