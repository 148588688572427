import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";


const Header = tw(HeaderBase)`max-w-none text-left`;
const Row = tw.div`flex flex-col  md:flex-row lg:flex-row justify-between items-center lg:-mb-32 max-w-screen-2xl mx-auto sm:px-0 lg:px-8  lg:h-[657px] xl:h-[657px]`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg  xl:max-w-2xl lg:max-w-xl -mt-20 lg:-mt-[8rem]  text-left `;
const Heading = tw(SectionHeading)`text-left mt-8 leading-snug lg:-mt-32 text-xl max-[420px]:text-[1.8rem] max-[768px]:text-3xl max-[920px]:text-2xl min-[921px]:text-3xl text-primary-900 font-sans font-bold min-[950px]:text-3xl  min-[1150px]:text-3xl min-[1250px]:text-4xl min-[1395px]:text-5xl min-[1395px]:-mt-[25px]`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline w-[270px] tracking-wide text-center py-5 lg:text-lg mr-[20px] md:block hidden font-sans`;
const PrimaryButton2 = tw(PrimaryButtonBase)`border-0 mt-[1.2rem] -ml-[35px] mx-auto inline w-[180px]  text-center text-[0.9rem] font-medium  md:block  bg-white text-gray-600 hover:bg-white hover:text-gray-800  hidden  underline underline-offset-8`;
const FeatureList = tw.ul`mt-8 leading-9  lg:text-lg max-[430px]:w-[120%] max-[430px]:-ml-[1rem]`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`-ml-[20px] sm:-ml-[0px] w-5 h-5 text-primary-500 `;
const FeatureText = tw.p`ml-2 font-medium text-gray-700 max-[400px]:text-base  max-[768px]:text-xl`;
const ImageColumn = tw(Column)` ml-[1.5rem] -mt-[1rem] lg:-mt-[250px] xl:-mt-[200px] w-screen  `;
const ImageContainer = tw.div`z-40 transform w-[100%] max-[768px]:w-[95%]  max-[980px]:w-[110%]  lg:ml-[3rem] xl:ml-[4rem] `;
const Image = tw.img`mt-[70px] md:mt-[0px] lg:-mt-0 max-[768px]:-mb-[2rem] xl:h-[600px]  min-[1100px]:max-[1280px]:h-[500px] min-[1100px]:max-[1279px]:w-[85%] min-[850px]:max-[950px]:h-[100%] min-[1100px]:max-[1166px]:w-[100%] `;
const StyledDiv = tw.div`font-display text-secondary-500 p-8 pb-0 overflow-hidden `;
const LinksHeader = tw.div`flex flex-col`;



export default ({
  heading = "Usamos WhatsApp para que tus clientes vuelvan a comprarte 🛒",
  description = "Genera recurrencia de compra en tu ecommerce.",
  // imageSrc = "https://desarrollospec.com/imagenes/2025.gif",
  imageSrc = "https://desarrollospec.com/imagenes/web-cliente-recurrente/opt/promover-recompra-ecommerce.gif",

  primaryButtonUrl = "#como-lo-hacemos",
  primaryButtonText = "¿Cómo lo hacemos?",
  buttonRounded = true,
  features = ["Promueve la recurrencia de compra", "Genera fidelización", "Proyecta tu consumo de stock"]
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [

    <NavLinks key={2}>      
    </NavLinks>
  ];
  return (
    <StyledDiv className="App">
      <Header links={navLinks} />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
              <LinksHeader>
              
                <PrimaryButton as="a" href={primaryButtonUrl} css={buttonRoundedCss}>
                  {primaryButtonText}
                </PrimaryButton>
                
                  <PrimaryButton2   css={buttonRoundedCss}>
                  <a href="https://wa.link/e9hezy" target="_blank">💬 Hablemos </a>
                </PrimaryButton2>
                
              </LinksHeader>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={imageSrc} />
              </ImageContainer>
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </StyledDiv>
  );
};
