import React, { useLayoutEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
import Footer from '../components/footers/FooterPecWeb';
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";

const Header = tw(HeaderBase)`max-w-none`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

// const Button2 = styled.div`text-blue-600`; 

const Button2 = tw(PrimaryButtonBase)` inline-block  tracking-wide text-center font-semibold py-1 lg:text-[16px] block bg-[#1877f2] rounded-[4px] h-[55px] border-0`;


const navLinks = [
  <NavLinks key={2}>      
  </NavLinks>
];

// const handleClick = () => {
//       // Launch Facebook login
//     FB.login(function (response) {
//       if (response.authResponse) {
//         const accessToken = response.authResponse.accessToken;
//         //Use this token to call the debug_token API and get the shared WABA's ID
//       } else {
//         console.log('User cancelled login or did not fully authorize.');
//       }
//     }, {
//       config_id: '891364266007378', // configuration ID obtained in the previous step goes here
//       response_type: 'code',     // must be set to 'code' for System User access token
//       override_default_response_type: true,
//       extras: {
//         setup: {
//            // Prefilled data can go here
//         }
//       }
//     });
// };

const Text = styled.div`
  ${tw`text-lg  text-gray-800 text-left`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Regístrate en Facebook con Desarrollos PEC" }) => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <AnimationRevealPage>
      <Header links={navLinks}/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Somos <b>Meta Business Partners</b> en la especialidad de WhatsApp.<br></br><br></br>
      A través de este proceso asociaremos tu empresa con Desarrollos PEC SpA y podremos activar tus campañas de WhatsApp automatizadas.</p><br></br><br></br>
          </Text>
          <Button2 onClick={() => launchWhatsAppSignup()}>
            Iniciar sesión con Facebook
          </Button2>
        </ContentWithPaddingXl>
      </Container>
      <Footer/>
    </AnimationRevealPage>
  );
};
