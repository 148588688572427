import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center p-2 z-50`;
const EscribenosAqui = tw(SectionDescription)`w-full text-center font-semibold  inline underline-offset-8`;


const Column = tw.div`flex flex-col items-center lg:mb-[5rem] mt-[2rem] xl:-mt-[1.2rem] lg:mt-[30px]
  max-[400px]:-mt-[25px] min-[401px]:-mt-[11px] 
  min-[420px]:mt-[70px]
  max-[349px]:-mt-[30px]
  `;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-2 max-w-4xl relative `;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between text-left`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed text-left`);

export default ({
  subheading = "Preguntas frecuentes",
  heading = "¿Dudas?",
  description = "",
  faqs = [
    {
      question: "¿Cómo puedo comenzar a utilizar el servicio y cuál es el costo?",
      answer:"A partir de una breve reunión revisamos los ajustes iniciales a la API de WhatsApp, es decir, el perfil del negocio en Meta/Facebook y cantidad de conversaciones a automatizar. Respecto al costo, los precios van de acuerdo a la cantidad de interacciones que vayamos a gestionar con nuestro SaaS.",
      answer2:"link",
    },
    {
      question: "¿En qué consisten los ajustes iniciales?",
      answer: "Para iniciar el proceso de integración vamos a considerar:",
      answer2: "1. Si la cuenta del Administrador Comercial de Facebook está verificada",
      answer3: "2. Tener disponible un número no asociado con una cuenta de WhatsApp*",
      answer4: "A partir de esos 2 puntos, te ayudamos a verificar el número y solicitar la aprobación de las plantillas de mensajes de tu ecommerce.",

    },
    {
      question: "¿Facilitan asistencia técnica durante la implementación?",
      answer:
        "¡Sí! Proporcionamos acompañamiento personalizado durante toda la experiencia con WhatsApp API, tanto en la parte técnica como en el copywriting usado en el desarrollo de tus experiencias."
    },
    {
      question: "¿Cuentan con plugins para CMS?",
      answer:"Contamos con integración para WordPress (WooCommerce), PrestaShop y Shopify vía API.",
      answer2: "De igual forma, quizás podemos realizar una implementación manual (sin plugins) o desarrollar una nueva integración para otro CMS. Escríbenos a hola@clienterecurrente.cl"
    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };
  

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>Si quieres avanzar o tienes alguna otra duda <EscribenosAqui><a href="https://wa.link/e9hezy" target="_blank"><u>escríbenos aquí 💬</u></a></EscribenosAqui></Description>
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                  {faq.answer2 === 'link' ? <><br></br><br></br><a href="https://wa.link/e9hezy" target="_blank">💬 Hablemos por WhatsApp</a></> 
                  : 
                  faq.answer2 ? (
                    <><br></br><br></br>{faq.answer2} </>
                  ) : (
                    <></>
                  )
                  
            
                }
                  {faq.answer3 ? (
                    <><br></br>{faq.answer3}</>
                  ) : (
                    <></>
                  )
                }

                {faq.answer4 ? (
                    <><br></br><br></br>{faq.answer4}</>
                  ) : (
                    <></>
                  )
                }

{faq.answer5 ? (
                    <><br></br><br></br>{faq.answer5}</>
                  ) : (
                    <></>
                  )
                }
                  
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
    </Container>
  );
};
