import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative lg:-mt-20`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-0 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-7/12 flex-shrink-0 h-96 md:h-auto relative p-0 m-0 w-[120%] -ml-[1.7rem] lg:w-[80%] lg:-ml-32 hidden lg:block`;
const ImageColumnMobile = tw(Column)`md:w-7/12 flex-shrink-0 h-96 md:h-auto relative p-0 m-0 w-[120%] -ml-[1.7rem] -mt-[2rem] lg:hidden min-[395px]:-mt-[0rem] max-[500px]:-mt-[2rem]`;

const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:-ml-40 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-[120%] w-[100%]  lg:h-[130%] p-0 m-0 lg:-ml-12 lg:-mt-8`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const VerticalSpacer = tw.div`mt-[2rem] w-full`

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-3xl sm:text-4xl lg:text-4xl text-center md:text-left leading-tight lg:w-[119%] font-sans lg:font-normal`;
const Description = tw.p`mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Statistics = tw.div`flex flex-col sm:block text-left md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div``;
const Key = tw.div`font-medium text-primary-500 text-left`;

export default ({
  subheading = "Campaña de recurrencia",
  heading = (
    <>
      <strong>Recordatorios de recompra</strong>
    </>
  ),
  description = (
    <>
    Ayuda a que tus clientes <strong>recuerden sus compras importantes</strong>: esos productos o servicios que les ofreces y son de uso recurrente. 
    <br></br><br></br>
    Tus clientes logran <strong>reponer sus productos de forma oportuna</strong> y tú generas confianza y fidelizas, todos ganan.
    </>
  ),
  // imageSrc = "https://desarrollospec.com/imagenes/2025.gif",
  imageSrc = "https://desarrollospec.com/imagenes/web-cliente-recurrente/gif-aviso-recompra-dimensionado.gif",

  imageCss = "",
  imageContainerCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "📦 Proyecta el stock futuro de tu ecommerce con más seguridad",
      value: ""
    },
    {
      key: "🤝 Genera confianza con tu cliente",
      value: ""
    },
    {
      key: "💎 Diferénciate de la competencia",
      value: ""
    },
    {
      key: "📈 Genera datos sobre el comportamiento de los clientes de tu ecommerce y toma mejores decisiones",
      value: ""
    }
  ];

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <ImageColumnMobile css={imageContainerCss}>
              <Image imageSrc={imageSrc} css={imageCss} /> 
            </ImageColumnMobile>
            <Description>{description}</Description>
            <VerticalSpacer/>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>

          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
