import React, { useLayoutEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
import Footer from '../components/footers/FooterPecWeb';

import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";

const Header = tw(HeaderBase)`max-w-none`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const navLinks = [
  <NavLinks key={2}>      
  </NavLinks>
];

const Text = styled.div`
  ${tw`text-lg  text-gray-800 text-left`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Política de privacidad" }) => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <AnimationRevealPage>
      <Header links={navLinks}/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Esta Política explica cómo 'Cliente recurrente' gestiona la información personal a través de nuestros servicios. Al utilizarlos, usted acepta nuestras prácticas de recopilación y uso.</p>
            <p>
              <b>1. Priorizamos la privacidad</b>: En 'Cliente recurrente', respetamos y protegemos la información sensible de cada negocio, colocándola en el centro de nuestra misión.<br></br>
              <b>2. Su información es confidencial</b>: No vendemos sus datos, y nuestro SaaS garantiza que la información de su negocio no se comparta con terceros con fines comerciales.<br></br>
              <b>3. Recopilación esencial</b>: Sólo recabamos la información necesaria para ofrecer servicios, cumplir compromisos y atender obligaciones legales.<br></br>
              <b>4. Seguridad y protección</b>: Nuestro equipo en 'Cliente recurrente' se esfuerza por mantener una comunidad segura, implementando medidas para prevenir la distribución de contenido dañino y violaciones de políticas en el SaaS.
            </p>
            <br></br>
            <h2>Interpretación y Definiciones</h2>
            <p>
            <br></br><b>Interpretación</b>
            </p>
            <p>
            Las palabras con inicial mayúscula tienen significados definidos. Las siguientes definiciones se aplican independientemente del número de veces que aparezcan.
            </p>
            <p>
            <br></br><b>Definiciones</b>
            </p>
            <p>
              <b>- Empresa</b>: Desarrollos PEC SpA, Agustinas 1022, of. 1004, Santiago, Chile.<br></br>
              <b>- Cookies</b>: Pequeños archivos que un sitio web coloca en su dispositivo, almacenando detalles de su historial de navegación.<br></br>
              <b>- País</b>: Santiago, Chile.<br></br>
              <b>- Dispositivo</b>: Cualquier aparato que acceda al Servicio, como computadora, teléfono o tableta.<br></br>
              <b>- Datos de su Negocio</b>: Información de una persona jurídica identificada o identificable.<br></br>
              <b>- Servicio</b>: Nuestro SaaS.<br></br>
              <b>- Proveedor de servicios</b>: Persona o entidad que procesa datos en nombre de la Empresa.            
            </p><br></br>
            <h2>Recopilación y Uso de Sus Datos de su Negocio</h2>
            <br></br>
            <p><b>Tipos de Datos Recopilados</b></p>
                <p>
                ‍Cuando utiliza nuestro Servicio, podríamos solicitarle cierta información personal, como:<br></br>
1. Dirección de correo electrónico<br></br>
2. Nombre y apellido<br></br>
3. Número de teléfono<br></br>
4. Dirección, estado, provincia, código postal, ciudad.<br></br>

                </p>
            <h2>Tecnologías de seguimiento y cookies</h2>

            <p>
            Utilizamos cookies y Google Analytics para rastrear la actividad y mejorar nuestro sitio web.
            </p>
            <h2>Uso de Sus Datos de su Negocio</h2>
            <p>La Empresa utiliza sus Datos de su Negocio para:<br></br><br></br>
‍
              1. Proporcionar y mantener nuestro Servicio, incluido el monitoreo de su uso.<br></br>
              2. Gestionar su cuenta y brindarle acceso a funcionalidades como usuario registrado.<br></br>
              3. Ejecutar contratos, desarrollar y cumplir compras de productos o servicios.<br></br>
              4. Contactarlo para actualizaciones, comunicaciones sobre funcionalidades y servicios contratados.<br></br>
              5. Ofrecer noticias, ofertas e información sobre bienes y eventos similares a los adquiridos.<br></br>
              6. Gestionar solicitudes y brindar soporte.<br></br>
              </p>
          

            <h2>Retención de sus Datos de su Negocio</h2>
            <p>
            La Empresa conserva sus Datos de su Negocio según las obligaciones legales y para análisis interno.
            </p><br></br>
            <h2>Transferencia de Sus Datos de su Negocio</h2>
            <p>
            Sus datos pueden procesarse fuera de su jurisdicción. Su consentimiento representa aceptación de esta transferencia, asegurando medidas de seguridad.
            </p><br></br>
            <h2>Información de Pago</h2>
            <p>
            Si adquiere servicios, solicitaremos un método de pago válido y detalles de facturación.
            </p><br></br>
            <h2>Divulgación </h2>
            <p>
            La Empresa divulgará Datos de su Negocio de buena fe para:<br></br><br></br>
1. Cumplir obligaciones legales<br></br>
2. Proteger derechos o bienes<br></br>
3. Prevenir o investigar infracciones relacionadas con el Servicio<br></br>
4. Proteger seguridad personal y legal.<br></br>

            </p><br></br>
            <h2>Seguridad de los Datos</h2>
            <p>
            'Cliente recurrente' se esfuerza por proteger la información, aunque no puede garantizar seguridad completa.
            </p><br></br>
            <h2>Enlaces a Otros Sitios Web</h2>
            <p>
            No controlamos ni respaldamos el contenido de sitios web de terceros.
            </p><br></br>
            <h2>Cambios a esta Política de Privacidad</h2>
            <p>
            Podemos actualizarla debido a cambios legales, estándares de la industria, servicios o prácticas comerciales. Cambios importantes se comunicarán previamente.
Recomendamos revisar periódicamente esta Política de Privacidad. Las actualizaciones entrarán en vigencia al publicarse.
            </p><br></br>
            <h2>Contáctenos</h2>
            <p>
            Para preguntas, escriba a hola@clienterecurrente.cl.
            </p>
            
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer/>
    </AnimationRevealPage>
  );
};
