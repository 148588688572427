import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import AnimationRevealPageFAQ from "../helpers/AnimationRevealPageFAQ.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "../components/hero/HeaderPecWeb"
import ComoLoHacemos from "../components/features/ComoLoHacemos.js";
import RecordatoriosDeRecompra from "../components/features/RecordatoriosDeRecompraTile.js";
import RecordatoriosDeRecompraV2 from "../components/features/RecordatoriosDeRecompraV2.js";
import ReactivacionDeClientesPerdidosTile from "../components/features/ReactivacionDeClientesPerdidos.js";
import IndustriasQueServimos from '../components/features/IndustriasQueServimos';
import SomosFacebookPartners from '../components/testimonials/SomosFacebookPartners.js';
import DashboardPecReview from '../components/testimonials/DashboardPecReview';
import PreguntasFrecuentesPec from '../components/faqs/PreguntasFrecuentesPec';
import Footer from '../components/footers/FooterPecWeb';
import CitaZendesk from '../components/testimonials/CitaZendesk';

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
const StyledDiv2 = tw.div`font-display min-h-screen text-secondary-500 pl-8 pr-8 overflow-hidden`;
const StyledDiv3 = tw.div`font-display text-secondary-500 p-12 overflow-hidden min-[1024px]:-mt-[80px]`;



export default ({ headingText = "Privacy Policy" }) => {
  return (
    <div className="App">
      <Header/>
      <AnimationRevealPage >
        <ComoLoHacemos/>
        {/* <RecordatoriosDeRecompra/> */}
        <RecordatoriosDeRecompraV2 />
        <ReactivacionDeClientesPerdidosTile/>
        </AnimationRevealPage>
        <StyledDiv2>
          <IndustriasQueServimos/>
          <DashboardPecReview/>
          <SomosFacebookPartners/>
        </StyledDiv2>
        {/* <CitaZendesk/> */}
        <AnimationRevealPageFAQ>
          <PreguntasFrecuentesPec/>
        </AnimationRevealPageFAQ>
      <StyledDiv3 >
        <Footer/>
      </StyledDiv3>
    </div>
  );
};
