import React, { useLayoutEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
import Footer from '../components/footers/FooterPecWeb';

const Header = tw(HeaderBase)`max-w-none`;

const navLinks = [
  <NavLinks key={2}>      
  </NavLinks>
];


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800 text-left`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Términos y Condiciones" }) => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <AnimationRevealPage>
      <Header links={navLinks}/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h2>I. Información General</h2>
            <p>
            El acceso y la utilización de este sitio web (www.clienterecurrente.cl) están sujetos a los términos y condiciones detallados a continuación. La aceptación de estos términos por parte de los visitantes del portal www.clienterecurrente.cl es fundamental para Desarrollos PEC SpA. Al aceptar estos términos, los visitantes y usuarios del Servicio SaaS proporcionado por la empresa declaran haber recibido información de manera clara, comprensible e inequívoca, además de tener la capacidad de almacenar e imprimir dichos términos.
            </p>
            <p>
            Con fines legales y para facilitar la comunicación entre los usuarios del sitio y la empresa ("Desarrollos PEC SpA"), se nombra a don Oscar Lopez como representante legal, con domicilio en calle Duble Almeyda 1960, comuna de Ñuñoa, ciudad de Santiago. Correo electrónico de contacto: oscar@desarrollospec.com. Teléfono de contacto: +56 9 5248 1981.
            </p>

            <h2>II. Derechos de los Usuarios</h2>
            <p>Los usuarios gozarán de todos los derechos reconocidos por la legislación de protección al consumidor en Chile, además de los derechos especificados en estos términos y condiciones. La visita a este sitio, donde se ofrecen servicios específicos, no implica obligación alguna para los usuarios, a menos que acepten de manera inequívoca las condiciones propuestas por la empresa, según lo indicado en estos términos y condiciones.</p>

            <h2>III. Protección de Datos Personales</h2>
            <p>
            La empresa utilizará exclusivamente los datos personales proporcionados por los usuarios para formalizar contratos de venta, recibir pagos y mejorar la información y comercialización de los servicios. Estos datos no serán compartidos con terceros no relacionados con la empresa. Los usuarios siempre tendrán derechos de información, rectificación y cancelación de sus datos personales de acuerdo con la ley.
            </p>

            <h2>IV. Responsabilidades Especiales de la Empresa</h2>
            <p>
            En las ventas realizadas en este sitio, la empresa proporcionará su dirección de correo electrónico y los medios técnicos para que el usuario pueda identificar y corregir errores en el envío o sus datos. Si se archiva un documento electrónico que formalice el contrato, se especificará cómo será accesible para el consumidor. Estas obligaciones se considerarán cumplidas cuando el usuario siga los pasos indicados en el sitio para realizar una compra específica.
            </p>

            <h2>V. Medios de Pago</h2>
            <p>
Salvo indicación diferente para casos u ofertas específicas, los servicios en este sitio solo podrán pagarse mediante transferencia electrónica a la cuenta corriente proporcionada por la empresa al usuario durante la confirmación de la transacción, una vez celebrado un contrato de implementación.
            </p>


            <h2>VI. Formación del Consentimiento en Contratos en el Sitio</h2>
            <p>
            En este sitio, la empresa ofrecerá a los usuarios ciertos servicios que podrán ser aceptados electrónicamente a partir de una primera reunión de coordinación.
            </p>

            <h2>VII. Responsabilidad por Enlaces</h2>
            <p>
            La empresa no asume responsabilidad por la información proporcionada en otras páginas web enlazadas con este sitio. Aunque la empresa no controla ni supervisa dichos enlaces, se espera que cumplan con la legislación aplicable. La utilización de enlaces a otros sitios no implica responsabilidad ni apropiación por parte de la empresa del contenido de los mismos. Se aconseja a los visitantes actuar con prudencia y revisar los términos y condiciones de esas páginas web, y la empresa no responderá por daños relacionados con el funcionamiento, disponibilidad y continuidad de los sitios enlazados.
            </p>

            <h2>VIII. Propiedad Intelectual e Industrial</h2>
            <p>
            Los textos, imágenes, logos, signos distintivos, animaciones, videos, códigos fuente y demás contenidos en este sitio son propiedad de la empresa o esta posee los derechos de reproducción. Constituyen bienes protegidos por la legislación de propiedad intelectual e industrial vigente. Cualquier transmisión, distribución, reproducción o almacenamiento de estos contenidos está prohibido sin el consentimiento expreso del titular. Los usuarios pueden reproducir o almacenar el contenido del sitio para uso personal, pero queda estrictamente prohibida la reproducción con fines comerciales.
            </p>

            <h2>IX. Jurisdicción, Competencia y Legislación Aplicable</h2>
            <p>
            Cualquier disputa entre los usuarios y la empresa relacionada con la interpretación y cumplimiento de estos términos y condiciones, así como las compras y ventas resultantes, será sometida a los Tribunales Ordinarios de Justicia de Santiago. Tanto los usuarios como la empresa se someten expresamente a esta jurisdicción y competencia. Estos términos y condiciones se regirán por las leyes de la República de Chile aplicables en cada caso.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
