import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import DogIcon from "images/dog-24.svg";
import HealthIcon from "images/bienestar-icono-recurrencia-de-compra.svg";

const Container = tw.div`relative sm:mt-[2rem] lg:mt-[4rem]`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center mx-auto py-16 w-full`}
`;
const Subheading = tw(SubheadingBase)`mb-4 lg:ml-0 `;
const Heading = tw(SectionHeading)`w-full font-sans lg:font-bold mb-4 sm:text-3xl font-bold md:text-4xl md:font-extrabold`;
const Description = tw(SectionDescription)`m-0 p-1 text-left  md:text-center leading-loose text-[1.2rem]`;

const VerticalSpacer = tw.div`lg:mt-[4rem] w-full mt-[1rem]`

const Column = styled.div`
  ${tw`md:w-1/2  max-w-sm lg:w-full lg:mr-20`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8 lg:mx-0 lg:px-0 lg:py-0 lg:ml-4 lg:w-[115%] sm:w-[100%] sm:-ml-[1rem] md:-ml-[0rem] md:w-[100%] `}
  .imageContainer {
    ${tw`border text-center rounded-full p-2 flex-shrink-0`}
    img {
      ${tw`w-10 h-10`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4  font-bold text-2xl sm:text-lg leading-none font-sans `}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose sm:w-[115%] md:w-[100%]`}
  }
`;

export default ({ cards = null, heading = "Industrias que potenciamos", subheading = "Nuestra especialidad", description = "Nos enfocamos en industrias de productos y servicios recurrentes que necesitan diferenciarse de su competencia y generar métricas claves para optimizar su gestión 🧠" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: DogIcon,
      title: "Industria de Mascotas",
      description: "Recordatorios personalizados de alimentos | Peluquería y cuidados | Vacunas y medicamentos | Snacks y premios"
    },
    { imageSrc: HealthIcon, title: "Industria de Bienestar", description: "Recordatorios personalizados de recompra de medicamentos y/o suplementos nutricionales | Recordatorios de sesiones de terapia o tratamiento" },


  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer" >
                <img src={card.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
